import React, { useState } from "react"
import { useQuery } from "react-query"
import { Location, Menu } from "./index.d"
import "./App.css"
import MenuHeader from "./components/MenuHeader"
import MenuItems from "./components/MenuItems"
import MenuSections from "./components/MenuSections"

const browserLanguage = window.navigator.language.split("-")[0]

const App = () => {
  const url = new URL(window.location.href)

  const getLocations = async (key: string): Promise<Location> => {
    return await fetch(
      `https://api.cloudflare.com/client/v4/accounts/${CLOUDFLARE_ACCOUNT_ID}/storage/kv/namespaces/${CLOUDFLARE_LOCATIONS_KV_ID}/values/${key}`,
      { headers: { Authorization: `Bearer ${CLOUDFLARE_KV_READ_TOKEN}`, Accept: "application/json" } }
    ).then((res) => res.json())
  }

  const getMenus = async (location: Location | null | undefined): Promise<Menu[]> => {
    if (!location) {
      return []
    }
    return await fetch(
      `https://api.cloudflare.com/client/v4/accounts/${CLOUDFLARE_ACCOUNT_ID}/storage/kv/namespaces/${CLOUDFLARE_MENUS_KV_ID}/values/${location.menu}`,
      { headers: { Authorization: `Bearer ${CLOUDFLARE_KV_READ_TOKEN}`, Accept: "application/json" } }
    ).then((res) => res.json())
  }

  console.log("url", url.pathname.replace("/", ""))
  console.log("lang", browserLanguage)
  console.log("account", CLOUDFLARE_ACCOUNT_ID)
  console.log("locations kv", CLOUDFLARE_LOCATIONS_KV_ID)
  console.log("menus kv", CLOUDFLARE_MENUS_KV_ID)
  console.log("token", CLOUDFLARE_KV_READ_TOKEN)
  const location = useQuery<Location>(["location"], async () => await getLocations(url.pathname.replace("/", "")))

  const menus = useQuery<Menu[]>(["menu"], async () => await getMenus(location.data))

  const [selectedLanguage, setSelectedLanguage] = useState(browserLanguage)

  console.log("location", location.data)

  if (!location.data || !menus.data) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <div className="mt-2">
        <div className="bg-white shadow border rounded-md p-2">
          <MenuHeader selectedLang={selectedLanguage} setSelectedLang={setSelectedLanguage} projectLangs={location.data.availableLangs} />
          <MenuSections selectedLang={selectedLanguage} menus={menus.data} />
          <div className="overflow-y-auto scroll-pt-4 snap-y h-phone-height overscroll-contain">
            <MenuItems selectedLang={selectedLanguage} currency={location.data.currency} menus={menus.data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
