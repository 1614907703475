/**
 *
 * AllergenIconSolid
 *
 */
import { memo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSeedling,
  faLeaf,
  faFishBones,
  faPig,
  faAcorn,
  faWheat,
  faFish,
  faEgg,
  faFarm,
  faTurkey,
  faCow,
  faCircleQuestion,
} from "@fortawesome/pro-solid-svg-icons"
import { useTranslation } from "react-i18next"
import { Tag } from "../../index.d"

interface Props {
  tag: Tag
  showName?: boolean
  showDescription?: boolean

  className?: string
}

export const AllergenIconSolid = memo((props: Props) => {
  const { t } = useTranslation()
  const { tag, showDescription, showName, className } = props

  const displayName = showName !== undefined ? showName : true

  const icon = {
    dairy: faFarm,
    eggs: faEgg,
    gluten: faWheat,
    fish: faFish,
    shellfish: faFishBones,
    pork: faPig,
    nut: faAcorn,
    beef: faCow,
    chicken: faTurkey,
    vegetarian: faSeedling,
    vegan: faLeaf,
  }

  if (tag !== undefined) {
    return (
      <>
        <FontAwesomeIcon className={className} icon={icon[tag.id] || faCircleQuestion} title={tag.description["en"]} />{" "}
        {displayName && <p>t(`common.tag_${tag.id}`, tag.name['en'])</p>}
        {showDescription && <span className="text-sm text-gray-400">{t(`common.tag_desc_${tag.id}`, tag.description["en"])}</span>}
      </>
    )
  } else {
    return (
      <>
        <FontAwesomeIcon className={className} icon={faCircleQuestion} title={"invalid tag"} /> --
      </>
    )
  }
})
